<template>
  <div class="pedido-listado">
        <section class="pedido-steps">
            <div class="container">
                <div class="row" v-if="this.loading == false">
                    <div class="col-sm-3">
                        <div class="form-group mt-4">
                            <label>Seleccione la tabla</label>
                            <select v-model="form.table">
                                <option v-for="(table,index) in this.tables" :key="index" :value="index">{{ table.label }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">                        
                        <div class="section-title">
                            <h5 class="pull-left">Importar nuevo archivo</h5>
                            <a href="javascript:void(0)" @click="openFileUploader" class="btn-green ml-4">Importar</a>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="return">
                            <router-link to="/">
                                <img :src="require('@/assets/img/backspace.svg')" class="mb-5">
                            </router-link>
                        </div>
                    </div>
                </div>
                
                
                <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
                <div class="row">
                    
                    <div class="col-sm-12 text-center mt-4">
                        <h3>Historial de importaciones</h3>
                    </div>
                </div>
                <form novalidate @submit.prevent="onSubmit">
                    <file-upload id="fileupload" v-model="form.file" @change="importXlsx" style="display: none;"></file-upload>
                </form>
                  <div class="row">
                    <div class="col-sm-12">
                        <div class="confirmado mt-2" v-if="this.loading == false">
                            <!--Para desktop-->
                            <table class="bg-white d-none d-sm-table" id="pedidosTable">
                                <thead>
                                    <tr style="background-color: #fffadc;">
                                        <th class="text-center">Nombre del archivo	 <Tooltip :parent="'id'" :message="'Nombre del archivo'">(?)</Tooltip></th>
                                        <th class="text-center">Tabla</th>
                                        <th class="text-center">Fecha de importación</th>
                                        <th class="text-center">Lineas importadas </th>
                                    </tr>
                                </thead>
                                <tbody id="mostrarTablas">
                                    <tr class="b-top cuota-row" v-for="(file,index) in this.imports" :key="index">
                                        <td class="text-center" @click="downloadFile(file.filename)">{{ file.filename }}</td>
                                        <td class="text-center">{{ file.table }}</td>
                                        <td class="text-center">{{ file.date }}</td>
                                        <td class="text-center">{{ file.lines}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!--Para mobile hay que hacer una tabla por pedido-->
                            <table class="bg-white d-block d-sm-none pedidosTableMobile b-top cuota-row" v-for="(file,index) in this.imports" :key="index">
                                <tr @click="downloadFile(file.filename)">
                                    <th>Nombre del archivo</th>
                                    <td>{{ file.filename }}</td>
                                </tr>
                                <tr>
                                    <th>Tabla</th>
                                    <td>{{ file.plant }}</td>
                                </tr>
                                <tr>
                                    <th>Fecha de importación</th>
                                    <td>{{ file.date }}</td>
                                </tr>
                                <tr>
                                    <th>Lineas importadas</th>
                                    <td>{{ file.lines }}</td>
                                </tr>
                            </table>
                            
                        </div>
                        <div v-if="this.loading == true" class="loading"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import { route } from "../../enviroment.js"
import FileUpload from './components/FileUpload.vue'
import Tooltip from './components/Tooltip.vue'

export default {
    name: 'importadorPage',
    components: {
        FileUpload,Tooltip
    },
    data: function () {
        return {
            route : route,
            laravelData: {},
            filterData: {
                skus: [],
                channels: [],
            },
            tables: [],
            imports: [],
            filter: {},
            form: { 
                file: null,
                table: '',
                country: ''
            },
            loading: false
        }
    },                 
    created(){
        if (sessionStorage.getItem('country')) {
            this.form.country = this.quitarAcentos(sessionStorage.getItem('country')).toUpperCase()
        }
        this.getTables();
        this.getImports();
    },
    mounted(){
    },
    methods:{
        downloadFile(filename) {
            axios.get('/admin/download-file/' + filename 
            ).then((response) => {        
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                this.showAlert('success','Descarga exitosa!','');
            })
            .catch(error => {
                this.showAlert('warning','Atención!', error.response.data.message);
            });
        },
        getTables(){
            axios.get('/admin/tables'
            ).then((response) => {        
                this.tables = response.data.data
            })
            .catch((error) => {
                console.log(error)
            });
        },
        quitarAcentos(texto) {
            return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        },
        getImports(){           
            this.loading = true
            axios.get('/admin/imports/' + this.form.country 
            ).then((response) => {        
                this.imports = response.data.data
                this.loading = false
                // this.showAlert('success','Exito!', response.data.message);
            })
            .catch((error) => {
                this.showAlert('warning','Atención!', error);
            });
        },
        showAlert(type, title, message) {
            this.$refs.alert.showAlert(
                type, // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                title, // Header of the alert
                { 
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                } 
            )
        },
        cleanImportInput(){
            this.form.file = null
            document.getElementById('fileupload').value = null;
        },
        importXlsx() {
            if(this.form.table == ''){
                this.cleanImportInput()
                this.showAlert('warning','Atención!', "Seleccione una tabla");
                return
            }
            this.loading = true
            axios.post(`admin/import`, this.form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                this.showAlert('success','Exito!', response.data.message);
                this.cleanImportInput()
                this.getImports()
            })
            .catch((error) => {
                this.cleanImportInput()
                this.loading = false
                this.showAlert('warning','Atención!', error.response.data.message);
            });
        },

        openFileUploader() {
            document.getElementById('fileupload').click()
        }
    }
}
</script>   
<style>

.cuota-row {
    cursor: pointer;
}

.cuota-row:hover {
    background-color: #e8e8e8;
}
</style>